import { Link } from 'gatsby';
import React, { useState } from 'react';
import Logo from '../../elements/Logo';
import MobileMenu from './MobileMenu';
import Nav from './Nav';
import StickyHeader from './StickyHeader';
import HeaderProductContent from './HeaderProductContent';

declare let gtag: any;

const HeaderCommon = () => {
  const [sticky, setSticky] = useState(false);
  const [linksWidth, setLinksWidth] = useState(0);

  const MobileMenuHandler = () => {
    document.querySelector('.mobilemenu-popup')?.classList.toggle('show');
    document.querySelector('body')?.classList.toggle('mobilemenu-show');
  };

  return (
    <>
      <header
        className={`header banco-header header-style-2 ${sticky ? 'show-product-banner' : ''}`}
      >
        <div
          className={`banco-mainmenu ${StickyHeader(100) ? 'banco-sticky' : ''}`}
          onMouseLeave={() => setSticky(false)}
        >
          <div className="container-fluid">
            <div className="header-navbar">
              <div className="header-logo">
                <Logo limage="/images/logo-sticky.png" simage="/images/logo-sticky.png" />
              </div>
              <div className="header-main-nav">
                <Nav setSticky={setSticky} getLinksWidth={setLinksWidth} />
              </div>
              <div className="header-action">
                <ul className="list-unstyled">
                  <li className="header-btn">
                    <Link
                      to="https://app.banco.com.sg/login"
                      target="_blank"
                      className="banco-btn btn-borderd"
                    >
                      Login
                    </Link>
                  </li>
                  <li className="header-btn">
                    <a
                      href="https://app.banco.com.sg/register/account"
                      target="_blank"
                      className="banco-btn btn-fill-primary"
                      onClick={(e) => {
                        e.preventDefault();
                        gtag('event', 'conversion', {
                          send_to: 'AW-10939055673/K2UPCPXIroAYELn8kuAo',
                        });
                        window.open('https://app.banco.com.sg/register/account');
                      }}
                    >
                      Sign up
                    </a>
                  </li>
                  <li className="mobile-menu-btn sidemenu-btn d-lg-none d-block">
                    <button className="btn-wrap" onClick={MobileMenuHandler}>
                      <span></span>
                      <span></span>
                      <span></span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <HeaderProductContent linksWidth={linksWidth} />
        </div>
      </header>
      <MobileMenu MobileHandler={MobileMenuHandler} />
    </>
  );
};
export default HeaderCommon;
