import React from 'react';
import { HeaderRightCaretSvg } from '../../../static/svg/companySvgCom';

interface HeaderProductContentProps {
  linksWidth: number;
}

const HeaderProductContent: React.FC<HeaderProductContentProps> = ({ linksWidth }) => (
  <div className="header-product-banner">
    <div className="container-fluid">
      <h3>Our Product</h3>
      <div className="row" style={{ width: linksWidth + 355, marginLeft: 0 }}>
        <div className="col-6">
          <p className="-title">
            <a href="/invoice-paid">
              InvoicePaid+ <HeaderRightCaretSvg />
            </a>
          </p>
          <p className="-description">
            Collect your monthly invoice on time, every time. Instant Approval. Same day
            disbursement. Flat Fee. Minimal Documents.
          </p>
        </div>
        <div className="col-6">
          <p className="-title">
            <a href="/equip-paid">
              EquipPaid+ <HeaderRightCaretSvg />
            </a>
          </p>
          <p className="-description">
            Get 100% financing of your equipment with no downpayment. Conserve cashflow with fixed
            monthly payments.
          </p>
        </div>
        <div className="col-12">
          <p className="-title">
            <a href="/ap-ar">
              Accounts Payables / Receivables Automation <HeaderRightCaretSvg />
            </a>
          </p>
          <p className="-description">
            Reduce manual work and time spent on exceptional handling. Free up your team to focus on
            growing your business. Leave the receivables, payables to our banco AP/AR automation
            tool.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default HeaderProductContent;
