import moment from 'moment';
import React from 'react';
import { BiChevronLeft } from 'react-icons/bi';

type DetailSectionProps = {
  title: JSX.Element | string;
  date: string;
  readTime: number;
  type: string;
};

const DetailSection = ({ title, date, readTime, type }: DetailSectionProps) => (
  <div className="breadcrum-area breadcrumb-detail">
    <div className="container container-detail">
      <div className="section-heading">
        <a className="link" href={type === 'blog' ? '/blog' : '/media-mention'}>
          <BiChevronLeft />
          back to {type === 'blog' ? 'Blogs' : 'Media mentions'}
        </a>
        <h1 className="title h3">{title}</h1>
        <div className="info">
          <ul className="media-meta list-unstyled">
            <li>{moment(date).format('MMMM DD, YYYY')}</li>
            <li>{readTime} min read</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
);

export default DetailSection;
