import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
// import moment from 'moment';
import React from 'react';

type BlogItemProps = {
  data: {
    id: string;
    Title: string;
    Date: string;
    Description?: string;
    Cover?: any;
    Slug: string;
    ReadMinutes: number;
  };
  mediaStyle?: string;
};

const BlogItem = ({ data, mediaStyle }: BlogItemProps) => (
  <div className={`media-grid ${mediaStyle ?? ''}`}>
    <div className="thumbnail">
      <Link to={`/blog/${data.Slug}`}>
        <GatsbyImage
          image={data.Cover?.localFile?.childImageSharp?.gatsbyImageData}
          alt={data.Title}
        />
      </Link>
    </div>
    <div className="content">
      <h3 className="title">
        <Link to={`/blog/${data.Slug}`} title={data.Title}>
          {data.Title}
        </Link>
      </h3>
      <p>{data.Description ?? ''}</p>
      <div className="info">
        <ul className="media-meta list-unstyled">
          {/* <li>{moment(data.Date).format('MMMM DD, YYYY')}</li> */}
          <li>{data.ReadMinutes} min read</li>
        </ul>
      </div>
    </div>
    <Link className="banco-btn btn-borderd" to={`/blog/${data.Slug}`}>
      Read more
    </Link>
  </div>
);

export default BlogItem;
