import React from 'react';

type SectionTitleProps = {
  subtitle?: string;
  title: string;
  description?: string;
  textAlignment?: string;
  textColor?: string;
};

const SectionTitle = ({
  subtitle,
  title,
  description,
  textAlignment,
  textColor,
}: SectionTitleProps) => (
  <div className={`section-heading ${textAlignment ?? ''} ${textColor ?? ''}`}>
    {subtitle && <div className="subtitle" dangerouslySetInnerHTML={{ __html: subtitle }}></div>}
    <h2 className="title" dangerouslySetInnerHTML={{ __html: title }}></h2>
    {description && <p dangerouslySetInnerHTML={{ __html: description }}></p>}
  </div>
);
export default SectionTitle;
